import logo from './logo.svg';
import './App.css';
import StudentRegistrationApp from './StudentRegistrationApp';

function App() {
  return (
   <StudentRegistrationApp />
  );
}

export default App;
