import React, { useState } from 'react';
import { Calendar } from 'lucide-react';

const StudentRegistrationApp = () => {
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [view, setView] = useState('calendar'); // calendar, spreadsheet, registration
  const [searchQuery, setSearchQuery] = useState('');

  // Sample student data
  const students = [
    {
      id: 1,
      name: 'John Doe',
      registrationDate: '2024-10-28',
      contact: 'john@example.com',
      amountOwed: 500,
      paymentStatus: 'Unpaid',
      notes: 'Pending documentation'
    },
    // Add more sample data as needed
  ];

  const renderCalendarView = () => (
    <div className="w-full bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center gap-2 text-xl font-semibold">
          <Calendar className="h-6 w-6" />
          Student Registration Calendar
        </div>
        <p className="text-gray-500 mt-1">
          Select a week to view registered students
        </p>
      </div>
      <div className="p-6">
        <div className="grid grid-cols-7 gap-1">
          {/* Generate calendar days */}
          {Array.from({ length: 31 }, (_, i) => (
            <div
              key={i}
              className="p-4 border rounded hover:bg-blue-50 cursor-pointer text-center"
              onClick={() => {
                setSelectedWeek(Math.floor(i / 7));
                setView('spreadsheet');
              }}
            >
              {i + 1}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderSpreadsheetView = () => (
    <div className="w-full bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">Student List - Week {selectedWeek + 1}</h2>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setView('calendar')}
          >
            Back to Calendar
          </button>
        </div>
        <div className="flex gap-4 mt-4">
          <input
            type="text"
            placeholder="Search students..."
            className="p-2 border rounded w-full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            onClick={() => setView('registration')}
          >
            New Registration
          </button>
        </div>
      </div>
      <div className="p-6">
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Student Name
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Registration Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Contact
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Amount Owed
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Payment Status
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Notes
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {students.map((student) => (
                <tr key={student.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{student.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.registrationDate}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.contact}</td>
                  <td className="px-6 py-4 whitespace-nowrap">${student.amountOwed}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.paymentStatus}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{student.notes}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );

  const renderRegistrationForm = () => (
    <div className="w-full bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-semibold">New Student Registration</h2>
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={() => setView('spreadsheet')}
          >
            Back to List
          </button>
        </div>
      </div>
      <div className="p-6">
        <form className="space-y-4">
          {/* Application Details */}
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Application Details</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium">Application Type</label>
                <select className="w-full p-2 border rounded">
                  <option>Select type...</option>
                  {/* Add application types */}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium">Purchase Order?</label>
                <input type="checkbox" className="mt-2" />
              </div>
            </div>
          </div>

          {/* Personal Information */}
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Personal Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium">First Name</label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
              <div>
                <label className="block text-sm font-medium">Last Name</label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
              <div>
                <label className="block text-sm font-medium">CCO ID</label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
              <div>
                <label className="block text-sm font-medium">Date of Birth</label>
                <input type="date" className="w-full p-2 border rounded" />
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className="space-y-2">
            <h3 className="text-lg font-semibold">Contact Information</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium">Email</label>
                <input type="email" className="w-full p-2 border rounded" />
              </div>
              <div>
                <label className="block text-sm font-medium">Phone</label>
                <input type="tel" className="w-full p-2 border rounded" />
              </div>
              <div className="col-span-2">
                <label className="block text-sm font-medium">Address</label>
                <input type="text" className="w-full p-2 border rounded" />
              </div>
            </div>
          </div>

          <div className="flex justify-end">
            <button
              type="submit"
              className="px-6 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              Register Student
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      {view === 'calendar' && renderCalendarView()}
      {view === 'spreadsheet' && renderSpreadsheetView()}
      {view === 'registration' && renderRegistrationForm()}
    </div>
  );
};

export default StudentRegistrationApp;
